import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import TalkContent from "components/talks/TalkContent";
import TweetWrapper from "components/common/TweetWrapper";
import SEO from "components/layout/SEO";
export const _frontmatter = {
  "title": "Fluttering Your Wings With React Native",
  "path": "talks/fluttering-your-wings-with-react-native",
  "shortInfo": "Lessons learned while building a communication platform with React Native",
  "position": 1,
  "thumbnail": "./thumbnail.png",
  "videoLength": "18min",
  "place": "GeekyAnts",
  "date": "26 Jan '19"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TalkContent title="Fluttering Your Wings With React Native" shortInfo="Lessons learned while building a communication platform with React Native" youtubeUrl="https://www.youtube.com/watch?v=e0okQCIArKw" mdxType="TalkContent">
      <SEO title="Fluttering Your Wings With React Native - Chaitanya Deorukhkar" description="Lessons learned while building a communication platform with React Native" image="talks/fluttering-wings.png" path="talks/fluttering-your-wings-with-react-native" mdxType="SEO" />
      <h2>{`Significance`}</h2>
      <p>{`This was my first ever talk! The cherry on the cake was the fact that I presented it at `}<a parentName="p" {...{
          "href": "https://twitter.com/geekyants"
        }}>{`GeekyAnts`}</a>{`, the first every UI library I used with React Native at my first ever job. Too many first evers.`}</p>
      <h2>{`Forming a mental model`}</h2>
      <p>{`Building an app with React Native meant you need to be a jack of all trades and master of some. This talk is a summary of all the challenges I faced while building a production-ready app and how I solved some of those challenges (so that you don't have to solve them again).`}</p>
      <h2>{`I talk about`}</h2>
      <ul>
        <li parentName="ul">{`KeyboardAvoidView`}</li>
        <li parentName="ul">{`StatusBar with a SafeAreaView`}</li>
        <li parentName="ul">{`Performance optimisation by avoiding re-renders`}</li>
        <li parentName="ul">{`UI Scaling based on different device sizes`}</li>
        <li parentName="ul">{`Type Checking`}</li>
        <li parentName="ul">{`Push Notifications`}</li>
        <li parentName="ul">{`Future of React Native`}</li>
        <li parentName="ul">{`Is Flutter better than React Native? 🤔`}</li>
      </ul>
      <br />
      <br />
      <br />
      <br />
      <h2>{`Featured Tweets`}</h2>
      <TweetWrapper mdxType="TweetWrapper">
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Drop in `}<a parentName="p" {...{
                "href": "https://twitter.com/geekyants"
              }}>{`@geekyants`}</a>{` for my talk on `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/ReactNative?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#ReactNative`}</a>{` :)`}<br parentName="p"></br><br parentName="p"></br>{`PS: The pun is intentional :P `}<a parentName="p" {...{
                "href": "https://t.co/SWGQVP0FMH"
              }}>{`https://t.co/SWGQVP0FMH`}</a></p>{`— Chaitanya Deorukhkar (@_anothercoder) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/_anothercoder/status/1085547492152770560"
            }}>{`January 16, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`All set for the React Native and Flutter Bangalore meetup! 🔥 `}<a parentName="p" {...{
                "href": "https://t.co/VUQDzIsse8"
              }}>{`pic.twitter.com/VUQDzIsse8`}</a></p>{`— Sanket Sahu (@sanketsahu) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/sanketsahu/status/1089070861800239104"
            }}>{`January 26, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`This brings `}<a parentName="p" {...{
                "href": "https://twitter.com/ReactNativeBLR"
              }}>{`@ReactNativeBLR`}</a>{` to a happy ending (Notice the RN and Flutter speakers smiling together) It was a lot of fun to host wonderful people and we look forward to another one. Until then... `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/React?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#React`}</a>{` `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/reactnative?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#reactnative`}</a>{` `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/reactjs?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#reactjs`}</a>{` `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/Flutter?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#Flutter`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/EXaTv6APRZ"
              }}>{`pic.twitter.com/EXaTv6APRZ`}</a></p>{`— React Native BLR (@ReactNativeBLR) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/ReactNativeBLR/status/1089139401089679360"
            }}>{`January 26, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`React Native in full force with `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{` sharing his experience in React Native and the problems he had (and the solutions he used).`}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/reactnative?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#reactnative`}</a>{` `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/reactjs?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#reactjs`}</a>{` `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/React?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#React`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/dlhPifXT72"
              }}>{`pic.twitter.com/dlhPifXT72`}</a></p>{`— React Native BLR (@ReactNativeBLR) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/ReactNativeBLR/status/1089104174501916672"
            }}>{`January 26, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Here `}<a parentName="p" {...{
                "href": "https://twitter.com/reactnative"
              }}>{`@reactnative`}</a>{` continue with `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{` on Fluttering your wings smoothly with React Native at `}<a parentName="p" {...{
                "href": "https://twitter.com/geekyants"
              }}>{`@geekyants`}</a>{` .`}<br parentName="p"></br>{`Here also `}<a parentName="p" {...{
                "href": "https://twitter.com/flutterio"
              }}>{`@flutterio`}</a>{` got highlighted😂😂`}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/rnblr?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#rnblr`}</a>{` `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/flutterblr?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#flutterblr`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/d8sQYNBxnU"
              }}>{`pic.twitter.com/d8sQYNBxnU`}</a></p>{`— Ruchika (@ruchikaSjv) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/ruchikaSjv/status/1089101227340705792"
            }}>{`January 26, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Thank you for hosting me and for these wonderful souvenirs `}<a parentName="p" {...{
                "href": "https://twitter.com/geekyants"
              }}>{`@geekyants`}</a>{` `}<a parentName="p" {...{
                "href": "https://twitter.com/sanketsahu"
              }}>{`@sanketsahu`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/WyqsT7UWmq"
              }}>{`pic.twitter.com/WyqsT7UWmq`}</a></p>{`— Chaitanya Deorukhkar (@_anothercoder) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/_anothercoder/status/1089160556219588608"
            }}>{`January 26, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`But this Question continues!!!!!!😂😂😂`}<br parentName="p"></br>{`Is `}<a parentName="p" {...{
                "href": "https://twitter.com/flutterio"
              }}>{`@flutterio`}</a>{` better than `}<a parentName="p" {...{
                "href": "https://twitter.com/reactnative"
              }}>{`@reactnative`}</a>{` ????`}<br parentName="p"></br><br parentName="p"></br>{`Great talk by Chaitanya `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{`👏👏👏 `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/rnblr?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#rnblr`}</a>{` `}<a parentName="p" {...{
                "href": "https://twitter.com/hashtag/flutterblr?src=hash&ref_src=twsrc%5Etfw"
              }}>{`#flutterblr`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/SgBENfRUwB"
              }}>{`pic.twitter.com/SgBENfRUwB`}</a></p>{`— Ruchika (@ruchikaSjv) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/ruchikaSjv/status/1089105063702712320"
            }}>{`January 26, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`Here's my talk at `}<a parentName="p" {...{
                "href": "https://twitter.com/geekyants"
              }}>{`@geekyants`}</a>{` on "Fluttering your wings smoothly with React Native"`}<br parentName="p"></br><br parentName="p"></br>{` ✅ First tech talk`}<a parentName="p" {...{
                "href": "https://t.co/xvg17hKBR7"
              }}>{`https://t.co/xvg17hKBR7`}</a></p>{`— Chaitanya Deorukhkar (@_anothercoder) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/_anothercoder/status/1093448455584796673"
            }}>{`February 7, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`One of the best thing you will see today.`}<br parentName="p"></br>{`"You're not the developer we deserve but the one we need"`}<br parentName="p"></br>{`Loved it man. `}<a parentName="p" {...{
                "href": "https://t.co/5XjkPjhRJG"
              }}>{`https://t.co/5XjkPjhRJG`}</a></p>{`— Sumant Raj (@sumantRaj2611) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/sumantRaj2611/status/1093547887609417729"
            }}>{`February 7, 2019`}</a></blockquote></p>
        <p><blockquote parentName="p" {...{
            "className": "twitter-tweet",
            "data-dnt": "true"
          }}><p parentName="blockquote" {...{
              "lang": "en",
              "dir": "ltr"
            }}>{`What a talk man!! Impressively curatored.. Loved it `}<a parentName="p" {...{
                "href": "https://twitter.com/_anothercoder"
              }}>{`@_anothercoder`}</a>{` `}<a parentName="p" {...{
                "href": "https://t.co/3XX0TPYO08"
              }}>{`pic.twitter.com/3XX0TPYO08`}</a></p>{`— Jitesh (@droidboy313) `}<a parentName="blockquote" {...{
              "href": "https://twitter.com/droidboy313/status/1098086410265870338"
            }}>{`February 20, 2019`}</a></blockquote></p>
      </TweetWrapper>
    </TalkContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      